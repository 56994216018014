<script setup>
import {createVNode, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import { Head, Link, router } from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import NavLink from '@/Components/NavLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import ApplicationLogoFooter from '@/Components/ApplicationLogoFooter.vue';
import ApplicationLogoTextRight from "@/Components/ApplicationLogoTextRight.vue";
import SecondaryButton from '@/Components/SecondaryButton.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import UserAvatar from '@/Components/UserAvatar.vue';
import { Modal } from '/vendor/emargareten/inertia-modal';
import ModalNotifications from "@/Components/Notifications/ModalNotifications.vue";
import {toast} from "vue3-toastify";
import PushNotification from "@/Components/Notifications/PushNotification.vue";
import AvatarImage from "@/Components/AvatarImage.vue";
import CookieConsent from "@/Components/CookieConsent.vue";
import {useGtm} from "@gtm-support/vue-gtm";

defineProps({
    title: String,
    description: String,
    keywords: String,
    footer_links: Object,
});
const showingNavigationDropdown = ref(false);
const pageProps = getCurrentInstance().appContext.config.globalProperties.$page;
const unreadNotifications = ref(pageProps.props.unreadNotifications);
const gtm = useGtm();

const { open: openNotificationsModal, close: closeNotificationsModal } = useModal({
    component: ModalNotifications,
    attrs: {
        title: 'Notifications',
        onCloseNotificationsModal() {
            closeNotificationsModal();
        },
        onReadNotifications(hasUnreadNotifications) {
            unreadNotifications.value = hasUnreadNotifications
        },
    },
});

const openLoginModal = () => {
    router.get(route('show.login'));
};

const logout = () => {
    triggerLogoutGtmEvent()
    router.post(route('logout'));
};

const triggerLogoutGtmEvent = () => {
    const user = pageProps.props.auth.user;
    gtm.trackEvent({
        event: "logout_success",
        category: "User Actions",
        action: "Logout",
        label: 'logout',
        value: user.id,
        user_details: {
            user_id: user.id,
            first_name: user.main_profile.first_name,
            last_name: user.main_profile.last_name,
            display_name: user.main_profile.display_name,
            email: user.email,
            phone: user.phone,
        },
    });
}

onMounted(() => {
    if (!pageProps.props?.auth?.user) {
        return;
    }
    window.Echo.private(`App.Models.User.${pageProps.props?.auth?.user.id}`).notification((notification) => {
        unreadNotifications.value = true;
        toast({
            render: (props) => {
                return createVNode(PushNotification, {
                    notification: notification
                });
            },
        },
        {
            autoClose: 20000,
            position: 'top-right',
            onClick: () => openNotificationsModal(),
        });
    })
});
onUnmounted(() => {
    if (pageProps.props?.auth?.user) {
        window.Echo.leave(`App.Models.User.${pageProps.props?.auth?.user.id}`);
    }
});
</script>

<template>
    <div>
        <Head>
            <meta name="description" :content="description ? description : 'Immortalize your life story for future generations. Join our free social platform to tell your story, be inspired, and mentored!'">
            <meta name="keywords" :content="keywords ? keywords : 'immortalize, life stories, remembrance, passing away, healing, eulogy, autobiography, biography, mentorship, mentorship networking, social networking, mentorship network, social network, immortalize me, timepiece, share, Trustee, life, end of life, memorialize, memorial, family, friends, mentor, mentee, life journey, timeline, life reel, inspire, inspiration, life journey'">
            <title>{{ title ? title : 'Immortalize - Free social platform to share your real-life stories' }}</title>
        </Head>
        <Banner />
        <div class="min-h-screen flex flex-col">
            <nav :class="{'bg-white': $page.url !== '/'}">
                <!-- Primary Navigation Menu -->
                <div class="max-w-7xl mx-auto px-4 sm:px-16 lg:px-28">
                    <div class="flex justify-between h-20 max-w-7xl mx-auto">
                        <div class="flex flex-1 justify-between">
                            <!-- Logo -->
                            <div class="flex items-center text-left">
                                <Link class="flex flex-row gap-1 justify-items-center" :href="route('home')">
                                    <ApplicationLogoTextRight />
                                </Link>
                            </div>

                            <!-- Navigation Links -->
                            <div class="hidden space-x-8 md:-my-px md:ml-10 md:flex">
                                <NavLink :href="route('explore')" :active="route().current('explore')">
                                    {{ $t('Explore') }}
                                </NavLink>
                                <NavLink v-show="!$page.props?.auth?.user" :href="route('about')" :active="route().current('about')">
                                    {{ $t('About') }}
                                </NavLink>
<!--                                <NavLink v-show="!$page.props?.auth?.user" :href="route('pricing')" :active="route().current('pricing')">-->
<!--                                    {{ $t('Pricing') }}-->
<!--                                </NavLink>-->
                                <NavLink v-show="!$page.props?.auth?.user" :href="route('help')" :active="route().current('help')">
                                    <inline-svg class="mr-2" src="/storage/icons/question-mark-circle.svg"></inline-svg>
                                    {{ $t('Help') }}
                                </NavLink>
                            </div>
                        </div>
                        <div class="hidden md:flex md:items-center sm:ml-6">
                            <!-- Account Dropdown -->
                            <div class="ml-3 relative flex flex-row items-center" v-if="$page.props?.auth?.user">
                                <Dropdown align="right" width="64">
                                    <template #trigger>
                                        <button type="button"
                                                class="flex flex-row gap-2 items-center px-4 py-2.5 border border-transparent
                                                text-sm font-semibold rounded-md hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50"
                                        >
                                            <avatar-image :md="true" :profile-photo="$page.props?.auth?.user.main_profile.profile_photo_url"></avatar-image>
                                            <span>{{ $page.props?.auth?.user.main_profile.full_name }}</span>
                                            <div>
                                                <inline-svg height="20" width="20" src="/storage/icons/chevron-down.svg"></inline-svg>
                                            </div>
                                        </button>
                                    </template>

                                    <template #content>
                                        <Link class="flex flex-col justify-center items-center py-6 px-4" :href="route('my.profile')">
                                            <avatar-image :normal="true" :profile-photo="$page.props?.auth?.user.main_profile.profile_photo_url"></avatar-image>
                                            <div class="text-slate-600 text-base font-semibold text-center">
                                                {{ $page.props?.auth?.user.main_profile.full_name}}
                                            </div>
                                            <div class="text-slate-600 text-xs font-normal leading-none break-all">
                                                {{ $page.props?.auth?.user.email }}
                                            </div>
                                        </Link>
                                        <DropdownLink
                                            :href="$page.props?.auth?.user.main_profile.remembrance
                                            ? route('remembrance.profile', {'slug': $page.props?.auth?.user.main_profile.slug})
                                            : route('remembrance.add', {'id': $page.props?.auth?.user.main_profile.id})"
                                        >
                                            <inline-svg src="/storage/icons/play-square.svg"/>
                                            {{ $t('My Life Reel') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('my.profile', {'viewMyCircle': true})">
                                            <inline-svg src="/storage/icons/layers-two-01.svg"/>
                                            {{ $t('My Circle') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('my.profile', {'viewTimepieces': true})">
                                            <inline-svg src="/storage/icons/layers-two-01.svg"/>
                                            {{ $t('Timepieces') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('add.timepiece')">
                                            <inline-svg src="/storage/icons/ic_create.svg"/>
                                            <div>
                                                <div>
                                                    {{ $t('Create new Timepiece') }}
                                                </div>
                                                <div class="text-slate-600 text-xs font-normal leading-none">
                                                    {{ $t('Add your stories') }}
                                                </div>
                                            </div>
                                            <inline-svg class="ml-auto" src="/storage/icons/chevron-right.svg"/>
                                        </DropdownLink>

                                        <div class="border-t border-gray-200" />
                                        <DropdownLink
                                            v-for="profile in $page.props?.auth?.user.profiles"
                                            :href="route('show.profile', {slug: profile.slug})"
                                        >
                                            <UserAvatar :md="true" :user="profile"></UserAvatar>
                                            <span v-if="!profile.status" class="rounded-full bg-teal-50 px-2 py-1 text-xs font-medium text-teal-600 ring-1 ring-inset ring-teal-600/10">
                                                Draft
                                            </span>
                                        </DropdownLink>
                                        <DropdownLink :href="route('immortalize.another')">
                                            <inline-svg src="/storage/icons/ic_add-user.svg"/>
                                            <div>
                                                <div>
                                                    {{ $t('Immortalize another') }}
                                                </div>
                                                <div class="text-slate-600 text-xs font-normal leading-none">
                                                    {{ $t('Add your stories') }}
                                                </div>
                                            </div>
                                            <inline-svg class="ml-auto" src="/storage/icons/chevron-right.svg"/>
                                        </DropdownLink>
                                        <div class="border-t border-gray-200" />
                                        <DropdownLink :href="route('profile.show')">
                                            <inline-svg src="/storage/icons/settings-01.svg"></inline-svg>
                                            {{ $t('Account Settings') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('coming.soon')">
                                            <inline-svg src="/storage/icons/message-smile-circle.svg"></inline-svg>
                                            {{ $t('Step by step Guide') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('coming.soon')">
                                            <inline-svg src="/storage/icons/help-circle.svg"></inline-svg>
                                            {{ $t('Site Tutorial') }}
                                        </DropdownLink>
                                        <DropdownLink v-if="!!$page?.props?.footer_links?.privacy"
                                                      :href="route('terms', {'slug': $page.props?.footer_links['privacy']})"
                                                      :active="route().current('terms')"
                                        >
                                            <inline-svg src="/storage/icons/file-06.svg"></inline-svg>
                                            {{ $t('Privacy Policy') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('about')">
                                            <inline-svg src="/storage/icons/infinity.svg"></inline-svg>
                                            {{ $t('About') }}
                                        </DropdownLink>
                                        <DropdownLink :href="route('help')">
                                            <inline-svg width="18" height="18" src="/storage/icons/question-mark-circle.svg"></inline-svg>
                                            {{ $t('Help') }}
                                        </DropdownLink>

                                        <div class="border-t border-gray-200" />
                                        <!-- Authentication -->
                                        <form @submit.prevent="logout">
                                            <DropdownLink as="button">
                                                <inline-svg src="/storage/icons/log-out-01.svg"></inline-svg>
                                                {{ $t('Log Out') }}
                                            </DropdownLink>
                                        </form>
                                    </template>
                                </Dropdown>
                                <button type="button"
                                        class="flex items-center ml-2 p-2.5 bg-gray-50 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 active:bg-gray-100 relative"
                                        @click="openNotificationsModal"
                                >
                                    <inline-svg src="/storage/icons/bell-02.svg"></inline-svg>
                                    <div v-if="unreadNotifications" class="w-3 h-3 top-2 right-2 absolute bg-red-700 rounded-full border-2 border-white"></div>
                                </button>
                            </div>
                            <template v-else>
                                <SecondaryButton @click="() => openLoginModal()" class="mr-2">
                                    {{ $t('Login') }}
                                </SecondaryButton>
                                <PrimaryButton @click="$inertia.visit(route('register'))">
                                    {{ $t('Sign up') }}
                                </PrimaryButton>
                            </template>
                        </div>

                        <!-- Hamburger -->
                        <div class="-mr-2 flex items-center md:hidden">
                            <button
                                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                                @click="showingNavigationDropdown = !showingNavigationDropdown">
                                <svg
                                    class="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        :class="{ hidden: showingNavigationDropdown, 'inline-flex': !showingNavigationDropdown,}"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        :class="{ hidden: !showingNavigationDropdown, 'inline-flex': showingNavigationDropdown,}"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Responsive Navigation Menu - mobile -->
                <div :class="{ block: showingNavigationDropdown, hidden: !showingNavigationDropdown, }" class="md:hidden">
                    <div class="pt-2 pb-3 space-y-1">
                        <ResponsiveNavLink :href="route('explore')" :active="route().current('explore')">
                            {{ $t('Explore') }}
                        </ResponsiveNavLink>
                        <ResponsiveNavLink :href="route('about')" :active="route().current('about')">
                            {{ $t('About') }}
                        </ResponsiveNavLink>
<!--                        <ResponsiveNavLink :href="route('pricing')" :active="route().current('pricing')">-->
<!--                            {{ $t('Pricing') }}-->
<!--                        </ResponsiveNavLink>-->
                        <ResponsiveNavLink :href="route('help')" :active="route().current('help')">
                            <div class="flex flex-row items-center">
                                <inline-svg class="mr-2" src="/storage/icons/question-mark-circle.svg"></inline-svg>
                                {{ $t('Help') }}
                            </div>
                        </ResponsiveNavLink>
                        <div class="flex flex-col p-4 gap-2.5" v-if="!$page.props?.auth?.user">
                            <SecondaryButton @click="() => openLoginModal()">
                                {{ $t('Login') }}
                            </SecondaryButton>
                            <PrimaryButton @click="$inertia.visit(route('register'))">
                                {{ $t('Sign up') }}
                            </PrimaryButton>
                        </div>
                        <!-- Responsive Settings Options -->
                        <div class="pt-4 pb-1 border-t border-gray-200" v-if="$page.props?.auth?.user">
                            <div class="flex items-center px-4">
                                <div v-if="$page.props.jetstream?.managesProfilePhotos" class="shrink-0 mr-3">
                                    <img class="h-10 w-10 rounded-full object-cover"
                                         :src="$page.props?.auth?.user.profile_photo_url"
                                         :alt="$page.props?.auth?.user.name"
                                    />
                                </div>
                                <div>
                                    <div class="font-medium text-base text-gray-800">
                                        {{ $page.props?.auth?.user.name }}
                                    </div>
                                    <div class="font-medium text-sm text-gray-500">
                                        {{ $page.props?.auth?.user.email }}
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 space-y-1">
                                <DropdownLink :href="route('my.profile')">
                                    <inline-svg src="/storage/icons/user-01-small.svg"></inline-svg>
                                    {{ $t('My Profile') }}
                                </DropdownLink>
                                <DropdownLink
                                    :href="$page.props?.auth?.user.main_profile.remembrance
                                            ? route('remembrance.profile', {'slug': $page.props?.auth?.user.main_profile.slug})
                                            : route('remembrance.add', {'id': $page.props?.auth?.user.main_profile.id})"
                                >
                                    <inline-svg src="/storage/icons/play-square.svg"></inline-svg>
                                    {{ $t('My Life Reel') }}
                                </DropdownLink>
                                <DropdownLink :href="route('my.profile', {'viewTimepieces': true})">
                                    <inline-svg src="/storage/icons/layers-two-01.svg"/>
                                    {{ $t('Timepieces') }}
                                </DropdownLink>
                                <DropdownLink :href="route('add.timepiece')">
                                    <inline-svg src="/storage/icons/ic_create.svg"/>
                                    <div>
                                        <div>
                                            {{ $t('Create new Timepiece') }}
                                        </div>
                                        <div class="text-slate-600 text-xs font-normal leading-none">
                                            {{ $t('Add your stories') }}
                                        </div>
                                    </div>
                                    <inline-svg class="ml-auto" src="/storage/icons/chevron-right.svg"/>
                                </DropdownLink>

                                <div class="border-t border-gray-200" />
                                <DropdownLink
                                    v-for="profile in $page.props?.auth?.user.profiles"
                                    :href="route('show.profile', {slug: profile.slug})"
                                >
                                    <UserAvatar :md="true" :user="profile"></UserAvatar>
                                    <span v-if="!profile.status" class="rounded-full bg-teal-50 px-2 py-1 text-xs font-medium text-teal-600 ring-1 ring-inset ring-teal-600/10">
                                        Draft
                                    </span>
                                </DropdownLink>
                                <DropdownLink :href="route('immortalize.another')">
                                    <inline-svg src="/storage/icons/ic_add-user.svg"/>
                                    <div>
                                        <div>
                                            {{ $t('Immortalize another') }}
                                        </div>
                                        <div class="text-slate-600 text-xs font-normal leading-none">
                                            {{ $t('Add your stories') }}
                                        </div>
                                    </div>
                                    <inline-svg class="ml-auto" src="/storage/icons/chevron-right.svg"/>
                                </DropdownLink>
                                <div class="border-t border-gray-200" />
                                <DropdownLink :href="route('profile.show')">
                                    <inline-svg src="/storage/icons/settings-01.svg"></inline-svg>
                                    {{ $t('Account Settings') }}
                                </DropdownLink>
                                <DropdownLink :href="route('coming.soon')">
                                    <inline-svg src="/storage/icons/message-smile-circle.svg"></inline-svg>
                                    {{ $t('Step by step Guide') }}
                                </DropdownLink>
                                <DropdownLink :href="route('coming.soon')">
                                    <inline-svg src="/storage/icons/help-circle.svg"></inline-svg>
                                    {{ $t('Site Tutorial') }}
                                </DropdownLink>
                                <DropdownLink v-if="!!$page?.props?.footer_links?.privacy"
                                              :href="route('terms', {'slug': $page.props?.footer_links['privacy']})"
                                              :active="route().current('terms')"
                                >
                                    <inline-svg src="/storage/icons/file-06.svg"></inline-svg>
                                    {{ $t('Privacy Policy') }}
                                </DropdownLink>
                                <DropdownLink :href="route('about')">
                                    <inline-svg src="/storage/icons/infinity.svg"></inline-svg>
                                    {{ $t('About') }}
                                </DropdownLink>
                                <DropdownLink :href="route('help')">
                                    <inline-svg src="/storage/icons/question-mark-circle.svg"></inline-svg>
                                    {{ $t('Help') }}
                                </DropdownLink>

                                <div class="border-t border-gray-200" />

                                <!-- Authentication -->
                                <form method="POST" @submit.prevent="logout">
                                    <ResponsiveNavLink as="button">
                                        {{ $t('Log Out') }}
                                    </ResponsiveNavLink>
                                </form>
                            </div>
                        </div>
<!--                        <div class="px-4">-->
<!--                            <div class="flex-col justify-center items-center gap-3 px-4 py-3 bg-teal-800 rounded-xl">-->
<!--                                <div class="flex flex-row justify-center">-->
<!--                                    <img src="/storage/icons/logo/logo-menu.svg" />-->
<!--                                </div>-->
<!--                                <div class="text-gray-50 text-lg font-medium text-center mt-3">-->
<!--                                    {{ $t('Get the app') }}-->
<!--                                </div>-->
<!--                                <div class="text-center text-teal-50 text-xs font-medium mt-3">-->
<!--                                    {{$t('Use the mobile app for a better experience')}}-->
<!--                                </div>-->
<!--                                <div class="flex flex-row justify-center gap-3 mt-3">-->
<!--                                    <img class="max-h-11 max-w-[92px]" src="/storage/images/apple-store-badge.svg" alt="apple"/>-->
<!--                                    <img class="max-h-11 max-w-[92px]" src="/storage/images/android-store-badge.svg" alt="android"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </nav>

            <!-- Page Heading -->
            <header v-if="$slots.header">
                <div class="w-full h-2/5">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page Content -->
            <main class="flex-1">
                <slot />
            </main>

            <div class="bg-teal-800 py-16 pb-12">
                <div class="mx-auto max-w-7xl px-4 sm:px-16 lg:px-28">
                    <div class="flex flex-1 justify-between items-center sm:items-start flex-col md:flex-row">
                        <div class="text-center sm:text-left">
                            <Link class="flex justify-center sm:justify-start"
                                  :href="route('home')"
                                  :active="route().current('home')"
                            >
                                <ApplicationLogoFooter />
                            </Link>
                            <div class="text-teal-100 text-base font-normal max-w-md my-8 text-center sm:text-left">
                                {{$t('We provide a platform for individuals all around the world to preserve and share life stories, memories, and biographies')}}
                            </div>
                            <div class="flex text-teal-100 text-base font-semibold leading-normal flex-col sm:flex-row mb-8 gap-x-8 gap-y-4">
                                <Link :href="route('explore')" :active="route().current('explore')">
                                    {{ $t('Explore') }}
                                </Link>
                                <Link :href="route('about')" :active="route().current('about')">
                                    {{ $t('About') }}
                                </Link>
                                <Link v-if="!!$page?.props?.footer_links?.terms" :href="route('terms', {'slug': $page.props?.footer_links['terms']})" :active="route().current('terms')">
                                    {{ $t('Terms & Conditions') }}
                                </Link>
                                <Link v-if="!!$page?.props?.footer_links?.gdpr" :href="route('terms', {'slug': $page.props?.footer_links['gdpr']})"  :active="route().current('terms')">
                                    {{ $t('GDPR') }}
                                </Link>
                                <Link :href="route('help')" :active="route().current('help')">
                                    {{ $t('Help') }}
                                </Link>
                                <Link v-if="!!$page?.props?.footer_links?.privacy" :href="route('terms', {'slug': $page.props?.footer_links['privacy']})" :active="route().current('terms')">
                                    {{ $t('Privacy') }}
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div class="w-32 text-white text-base font-semibold leading-normal mb-4 text-center sm:text-left">
                                {{ $t('Get the app') }}
                            </div>
                            <a href="#">
                                <img class="max-h-11 max-w-[132px] mb-4"
                                     src="/storage/images/apple-store-badge.svg"
                                     alt="apple"
                                />
                            </a>
                            <a href="#">
                                <img class="max-h-11 max-w-[132px]"
                                     style="height: 44px"
                                     src="/storage/images/android-store-badge.svg"
                                     alt="android"
                                />
                            </a>
                        </div>
                    </div>
                    <div class="flex pt-8 border-t border-gray-500 justify-between items-center mt-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-col sm:flex-row">
                        <div class="text-gray-300 text-base font-normal leading-normal mb-8 sm:mb-0">
                            © {{ new Date().getFullYear() }} Immortalize. {{ $t('All rights reserved') }}. | Version 1.45
                        </div>
                        <div class="justify-start items-center gap-6 flex">
                            <inline-svg src="/storage/icons/twitter-icon.svg"></inline-svg>
                            <inline-svg src="/storage/icons/linkedin-icon.svg"></inline-svg>
                            <inline-svg src="/storage/icons/facebook-icon.svg"></inline-svg>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
        <ClientOnly>
            <template>
                <div>
                    <ModalsContainer />
                </div>
            </template>
        </ClientOnly>
        <CookieConsent/>
    </div>
</template>
